.alumniWrapper {
  margin-bottom: 100px;
  .alumniGrid {
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(231, 231, 231);
      padding: 10px;
      height: 145px;

      img {
        max-width: 70%;
        max-height: 55px;
      }

      p {
        text-align: center;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: 575px) {
      width: 300px !important;
      margin: auto;
    }
  }
}
