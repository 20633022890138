.footerWrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  .footerTop {
    background-color: teal;
    padding: 35px 20px;
    text-align: center;

    p {
      color: white;
      font-size: 18px;
      text-align: center;
      margin: 0;

      a {
        color: white;
      }
    }

    @media only screen and (max-width: 500px) {
      padding: 20px 10px;

      p {
        font-size: 16px;
      }
    }
  }

  .footerBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(221, 221, 221);
    padding: 20px 20px;

    p {
      width: 65%;
      margin: 0;
    }

    .footerRight {
      width: 35%;
      display: flex;
      gap: 20px;
      justify-content: flex-end;

      @media only screen and (max-width: 1080px) {
        width: 100%;
        justify-content: center;
      }

      a {
        color: rgb(var(--teal-color)) !important;
        margin: 0;
      }
    }

    @media only screen and (max-width: 1080px) {
      flex-direction: column;
      gap: 20px;

      p {
        text-align: center;
        width: 100%;
      }
    }

    @media only screen and (max-width: 500px) {
      padding: 10px;

      p {
        font-size: 14px;
      }

      .footerRight {
        a {
          font-size: 14px;
          text-align: center;
        }
      }
    }

    @media only screen and (max-width: 400px) {
      p {
        font-size: 12px;
      }

      .footerRight {
        a {
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 715px) {
    position: relative;
    margin-top: 50px;
  }
}
