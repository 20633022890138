.sectionOneWrapper {
  // height: 100vh;

  .sectionOneTile {
    display: flex;
    align-items: center;

    &.imgTile {
      justify-content: start;
      padding-left: 0 !important;
      padding-right: 0 !important;

      img {
        width: 100%;
      }
    }

    &.textTile {
      flex-direction: column;
      justify-content: center;

      div {
        width: 70%;

        h1 {
          font-size: 40pt;
          font-weight: normal;
        }

        @media only screen and (max-width: 1234px) {
          h1 {
            font-size: 30pt;
          }
        }

        @media only screen and (max-width: 992px) {
          width: 80%;

          h1 {
            font-size: 30pt;
          }
        }

        @media only screen and (max-width: 768px) {
          margin-top: 20px;
        }

        @media only screen and (max-width: 576px) {
          width: 90%;

          h1 {
            font-size: 24pt;
          }
        }
      }
    }
  }
}
