.sectionSixWrapper {
  margin-bottom: 100px;
  margin-top: 150px;
  h2 {
    text-align: center;
  }
  .portfolioWrapper {
    margin-top: 30px;

    @media only screen and (max-width: 768px) {
      margin: 0 40px;
    }

    @media only screen and (max-width: 575px) {
      display: none;
    }
  }

  .portfolioWrapperMobile {
    display: none;
    margin-top: 30px;

    @media only screen and (max-width: 575px) {
      display: block;
    }
  }

  .alumniWrapper {
    a {
      background-color: rgb(var(--teal-color));
      color: white;
      text-decoration: none;
      display: inline-block;
      width: auto;
      padding: 15px;
      text-align: center;
      margin: 40px auto 0 auto;
      border-radius: 10px;
      border: 2px solid rgb(var(--teal-color));
      transition-duration: 0.3s;

      &:hover {
        color: rgb(var(--teal-color));
        background-color: transparent;
      }
    }

    @media only screen and (max-width: 460px) {
      padding: 0 30px;
    }
  }
}
