.personTile {
  padding: 10px;
  position: relative;
  cursor: pointer;

  &:hover {
    .imageWrapper {
      .layover {
        opacity: 0.65;

        & + p {
          font-weight: bold;
        }
      }

      .layoverText {
        opacity: 1;
      }
    }
    .personInfo {
      background-color: rgb(var(--teal-color));

      p {
        color: white;
      }
    }
  }

  .personInfo {
    transition-duration: 0.3s;
    padding: 5px;
    margin-top: 5px;

    .personName {
      font-size: 20px;
      margin-bottom: 0;
      text-align: center;

      @media only screen and (max-width: 500px) {
        font-size: 18px;
        margin-top: 5px;
      }
    }

    .personTitle {
      text-align: center;
      margin-bottom: 0;

      @media only screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
  }

  .imageWrapper {
    position: relative;
    padding: 0;

    img {
      width: 100%;
    }

    .layover {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(19, 18, 53);
      opacity: 0;
      transition-duration: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    .layoverText {
      color: white;
      font-weight: bold;
      font-size: 20px;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 11;
      transition-duration: 0.3s;
      text-align: center;

      @media only screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
  }
}
