.backToTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition-duration: 0.3s;
  opacity: 0;
  height: 0;
  width: 0;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);

  &.show {
    opacity: 0.75;
    cursor: pointer;
    width: 50px;
    height: 50px;

    &:hover {
      opacity: 1;
    }
  }
}
