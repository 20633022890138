.sectionThreeWrapper {
  margin-top: 100px;

  h2.investmentsHeader {
    text-align: center;
  }

  .investmentsWrapper {
    margin-top: 30px;

    @media only screen and (max-width: 992px) {
      row-gap: 75px;
    }

    @media only screen and (max-width: 768px) {
      row-gap: 30px;
    }
  }
}
