.investmentTile {
  padding: 0 !important;
  position: relative;

  @media only screen and (min-width: 501px) {
    &:hover {
      .tealBox {
        height: 280px;
        background-color: rgb(0, 164, 164);

        .tealBoxExpand {
          animation-name: fadeIn;
          animation-timing-function: linear;
        }
      }

      .layover {
        opacity: 0.5;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .layover {
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(19, 18, 53);
    opacity: 0;
    transition-duration: 0.3s;
  }

  .tealBox {
    background-color: rgb(var(--teal-color));
    z-index: 11;
    color: white;
    position: absolute;
    bottom: -50px;
    height: 160px;
    padding: 25px;
    width: 80%;
    left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    .tealBoxLink {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .tealBoxExpand {
      opacity: 0;
      z-index: 15;
      height: 0;
      animation-duration: 0.3s;
      transform-origin: bottom;
      animation-delay: 0.2s;
      animation-fill-mode: forwards;
    }

    a {
      z-index: 12;
    }

    * {
      color: white;
    }
  }

  @media only screen and (max-width: 1200px) {
    .tealBox {
      padding: 15px;
      height: 140px;
    }
  }

  @media only screen and (max-width: 992px) {
    box-sizing: border-box;

    .tealBox {
      padding: 25px;
      height: 160px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 400px;
    margin: 0 auto 80px auto;

    .tealBox {
      height: auto;

      .tealBoxExpand {
        opacity: 1;
        height: auto;
        animation: none;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      height: 0;
      opacity: 0;
    }
    50% {
      height: 100%;
      opacity: 0;
    }
    100% {
      opacity: 1;
      height: 100%;
    }
  }
}

@keyframes fadeOut {
  0% {
    height: auto;
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    height: 0;
  }
}
