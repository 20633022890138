.portfolioTile {
  border: 1px solid rgb(231, 231, 231);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;

  &:hover {
    .layoverWrapper {
      opacity: 0.95;
    }
  }

  img {
    width: 100%;
  }

  .layoverWrapper {
    text-decoration: none;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 152, 148);
    opacity: 0;
    justify-content: center;
    align-items: center;
    transition-duration: 0.3s;
    padding: 0 10px;

    p {
      color: white;
      text-align: center;
      padding: 0;
      margin: 0;
      line-height: 1.1;
      font-size: 12px;
    }
  }
}
