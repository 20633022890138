.modalWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(38, 38, 38, 0.5);
  backdrop-filter: blur(5px);
  z-index: 20;

  &.show {
    display: flex;
  }

  .modal {
    width: 1100px;
    position: relative;
    height: 100%;
    opacity: 0;
    animation-duration: 0.3s;
    // transform-origin: bottom;
    animation-fill-mode: forwards;

    &.show {
      animation-name: fadeIn;
      animation-timing-function: ease-in-out;
    }

    .modalContent {
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      min-height: 550px;
      width: 60%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding: 70px 100px 70px 50px;

      .modalClose {
        position: absolute;
        top: 20px;
        left: 20px;
        height: 30px;
        width: 30px;
        cursor: pointer;
      }

      h2 {
        margin-bottom: 0;
      }

      .modalTitle {
        font-size: 14px;
        color: rgb(var(--teal-color));
        font-weight: bold;
      }

      .modalListItem {
        margin-bottom: 10px;
      }
    }
    .modalImage {
      position: absolute;
      top: 50%;
      right: 0;
      width: 500px;
      height: 500px;
      transform: translateY(-50%);
    }

    .mobileImage {
      display: none;
    }

    @media only screen and (max-width: 1250px) {
      width: 850px;

      .modalContent {
        padding: 60px 120px 60px 50px;
        min-height: 525px;
        width: 76%;

        .modalClose {
          width: 20px;
          height: 20px;
        }
      }

      .modalImage {
        height: 300px;
        width: 300px;
      }
    }

    // @media only screen and (max-width: 880px) {
    //   width: 700px;
    //   overflow: scroll;

    //   .modalImage {
    //     width: 240px;
    //     height: 240px;
    //   }

    //   .modalContent {
    //     padding: 60px 65px 60px 40px;
    //     min-height: 550px;
    //   }
    // }

    @media only screen and (max-width: 880px) {
      width: 100%;
      display: block;
      margin: auto;
      overflow: scroll;

      .modalImage {
        display: none;
      }

      .mobileImage {
        display: block;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 30px;
        width: 60%;
        box-shadow: 0 0 10px rgba(54, 54, 54, 0.8);
      }

      .modalContent {
        padding: 30px;
        top: auto;
        left: auto;
        order: 2;
        position: relative;
        margin: auto;
        width: 60%;
        transform: none;
        flex: 1;
        display: block;
        box-shadow: 0 0 10px rgba(86, 86, 86, 0.4);
        margin-bottom: 50px;

        h2 {
          width: 80%;
        }

        .modalClose {
          top: 30px;
          right: 30px;
          left: auto;
        }
      }
    }

    @media only screen and (max-width: 570px) {
      .mobileImage,
      .modalContent {
        width: 80%;
      }
    }

    @media only screen and (max-width: 450px) {
      .mobileImage,
      .modalContent {
        width: 90%;
      }
    }
  }

  @media only screen and (max-width: 730px) {
    height: 100%;
    width: 100%;
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: flex;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
