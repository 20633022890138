.sectionFiveWrapper {
  margin-top: 120px;

  @media only screen and (max-width: 768px) {
    margin-top: 90px;
  }

  h2 {
    margin-bottom: 20px;
  }
}
