.sectionFourWrapper {
  margin-top: 100px;

  @media only screen and (max-width: 768px) {
    margin-top: 60px;
  }

  .distinctionSelectorWrapper {
    .distinctionSelector {
      display: flex;
      padding-left: 50px;
      gap: 40px;

      .distinctionSelectorLeft {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          margin-bottom: 40px;
        }

        .distinction {
          border-bottom: 1px solid rgba(142, 142, 142, 0.5);
          cursor: pointer;
          display: inline-block;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:nth-of-type(1) {
            border-top: 1px solid rgba(142, 142, 142, 0.5);
          }

          p {
            color: black;
            font-weight: bold;
            margin: 0;
            padding: 20px 0;
            display: inline-block;
            // transition-duration: 0.3s;
          }

          img {
            width: 20px;
            transition-duration: 0.3s;
          }

          &.active {
            p {
              font-weight: bold !important;
              color: rgb(var(--teal-color));
            }

            img {
              margin-right: -15px;
            }

            border-bottom: 2.5px solid rgb(var(--teal-color));
          }
        }
      }

      .distinctionSelectorRight {
        width: 70%;
        text-align: right;
        position: relative;

        img {
          width: 550px;
        }

        .distinctionContent {
          position: absolute;
          top: 30%;
          left: 0;
          background-color: white;
          width: 500px;
          padding: 50px 50px;
          text-align: left;

          .distinctionHeader {
            color: rgb(var(--teal-color));
          }
        }
      }

      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
    .mobileDistinctionSelector {
      display: none;

      @media only screen and (max-width: 991px) {
        display: block;
      }

      h2 {
        margin-bottom: 40px;
      }

      .mobileDistinctionAccordion {
        .mobileAccordionItem {
          border-left: none;
          border-right: none;
          border-width: 1px;
          border-radius: 0;
          border-color: rgba(162, 162, 162, 0.8);

          .mobileAccordionHeader {
            margin-bottom: 0;
            padding: 00px 0;
            font-weight: bold;
            --bs-accordion-btn-icon: url('../assets/images/icons/down-button.svg');
            --bs-accordion-btn-active-icon: url('../assets/images/icons/down-button.svg');

            button {
              font-weight: bold;
              font-size: 17px;

              &[aria-expanded='true'] {
                box-shadow: none;
                background-color: white;
                color: rgb(var(--teal-color));
              }
            }
          }

          .mobileAccordionBody {
            .name {
              margin-top: 25px;
              font-weight: bold;
              font-size: 17px;
            }

            .mobileAccordionImageWrapper {
              img {
                width: 100%;
              }

              width: 100%;
              height: 230px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
