.sectionTwoWrapper {
  padding: 0 !important;
  margin-top: 40px;

  .tealBanner {
    width: 100%;
    padding: 0 !important;
    margin: 0;
    background-color: rgb(var(--teal-color));
    height: 230px;
  }

  .sectionTwoContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -200px;

    .sectionTwoStats {
      width: 100%;
      margin-top: 20px;
      background-color: white;
      position: relative;

      div {
        margin-left: 0;
        padding: 35px 0;

        &:not(:first-child) {
          border-left: 1px solid rgb(131, 131, 131);
        }

        .statWrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 0;
          padding-bottom: 20px;

          .statNumber {
            color: rgb(var(--teal-color));
            font-size: 70px;
            font-weight: 200;
            line-height: 1.2;
            margin-bottom: 0;
            padding-top: 10px;
          }

          .statText {
            margin-bottom: 0;
          }
        }

        @media only screen and (max-width: 992px) {
          gap: 20px;
          div {
            &:not(:first-child) {
              border: none;
            }
            gap: 0;
          }
        }
      }
    }
    .sectionTwoAreas {
      h2.areasHeader {
        margin-left: -30px;
        padding: 30px 0px;
      }

      a{
        color: rgb(var(--teal-color));
      }

      .areasWrapper {
        gap: 40px;
        div {
          text-align: center;
          flex: 1;

          p.tealBox {
            background-color: rgb(var(--teal-color));
            padding: 7px 20px;
            color: white;
            display: inline-block;
          }
        }

        @media only screen and (max-width: 992px) {
          width: 50%;
          margin: auto;

          div {
            flex: auto;
          }
        }

        @media only screen and (max-width: 570px) {
          width: 80%;
        }
      }

      .bottomTealBox {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: 30px;

        p {
          width: 80%;
          background-color: rgb(var(--teal-color));
          padding: 45px 60px 30px 60px;
          margin: auto;
          font-size: 26px;

          @media only screen and (max-width: 530px) {
            width: 100%;
            padding: 25px 20px 10px 20px;
            font-size: 22px;
          }
        }
      }

      @media only screen and (max-width: 992px) {
        h2.areasHeader {
          margin-left: auto;
          text-align: center;
        }
      }
    }
  }
}
